import React from 'react';
import { Container, Loader } from './styles';

const Preloader = ({ show }) => {
  if (!show) {
    return <></>;
  }

  return (
    <Container>
      <Loader>
        <img src={require('@images/logo.png')} />
        <span>Syrian Exchange Prices</span>
        <div className="loader-box">
          <div className="loader">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </Loader>
    </Container>
  );
};

export default Preloader;
