import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const Loader = styled.div`
  background-color: #FFF;
  border: 0.4rem solid #4c2fbf;
  border-radius: 14rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28rem;
  height: 25rem;
  margin-top: -12.8rem;
  margin-left: -14rem;
  padding: 3rem;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: default;
  span {
    margin-bottom: 2.4rem;
    font-family: 'Roboto';
  }
  img {
    width: 8rem;
    margin-bottom: 3rem;
  }
`;