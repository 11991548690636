import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Preloader from '@components/preloader/index';
import { isSSR } from '@utils/helper';

const IndexPage = () => {
  useEffect(() => {
    async function loadUser() {
      const { isAuth } = await import('@utils/feathers-connect');
      const user = await isAuth();
      if (user) {
        navigate('/dashboard');
        return;
      }
      navigate('/login');
    }
    if (!isSSR) {
      setTimeout(() => {
        loadUser();
      }, 1000);
    }
  }, []);

  return (
    <div className="rtl">
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <div className="page-body">
            <Preloader show={true}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
